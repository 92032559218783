import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
        <div>
            
            <h1></h1><center>
                </center>
            <br/>
                <ul>
                    <li>The Iowa Special Use Bed Tracker provides an inventory of Iowa nursing facility beds available for individuals with a COVID-19-positive diagnosis being discharged from hospitals. </li>
                    <li>Nursing home providers: visit "Provider Updates" and input your current capacity for COVID beds</li>
                    <li>Hospital discharge planners: visit "Provider Beds Available" to search by city, county and zip code for available COVID nursing beds</li>
                    <li>Questions? Contact the Iowa Health Care Association; 515-978-2204 / info@iowahealthcare.org</li>
                </ul>
                
        <p></p>
       
      </div>
    );
  }
}