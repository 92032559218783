import React, { Component } from 'react';

import { useRef, useContext, useEffect } from "react";

export class VaccineStats extends Component {
    static displayName = VaccineStats.name;

    constructor(props) {
        super(props);
        this.state = { vaccines: [], loading: true, providerNum: '', zipcode: '', showall: true , sort: 3 };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleCountySort = this.handleCountySort.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        //this.populateProviderData();
    }

    handleCountySort(e) {
        e.preventDefault();
        if (this.state.sort === 3) {
            this.setState({ sort: 4 });
            this.populateVaccineData(4)
        }
        else {
            this.setState({ sort: 3 });
            this.populateVaccineData(3)
        }
    }

    handleSearchClick() {
        this.populateVaccineData(3);
    }
    componentDidMount() {
        //this.populateProviderData();
    }

    componentDidUpdate() {
        //this.populateProviderData();
    }

    renderVaccinesTable(vaccines) {
        return (
            <div>
                <p><strong>Bed availability- While every effort is made to keep this tool updated, Hospital Discharge Planners should contact the provider directly to determine the correct number of beds available for 
                    COVID positive patients. Hospital Discharge Planners seeking to transfer patients to SNFs should understand that admissions decisions are subject to State and Federal regulations and guidance. 
                  Provider participation in this tool is voluntary. Listing a bed on this tool does not provide a guaranty of admission for any specific individual as each potential resident’s care needs/ level of care will need to be considered.
            </strong></p>
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th><a href="#" onClick={this.handleCountySort}>County</a></th>
                            <th>Patients</th>
                            <th>Staff</th>
                            <th>Other</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vaccines.map(vaccine =>
                            <tr>
                                <td>{vaccine.county}</td>
                                <td>{vaccine.patients}</td>
                                <td>{vaccine.staff}</td>
                                <td>{vaccine.other}</td>
                                <td>{vaccine.total}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderVaccinesTable(this.state.vaccines);


        return (
            <div>
                <p><strong>Enter your facility ID and Zip code to search view vaccine statistics
            </strong></p>
                <label>
                    Provider ID:
          <input
                        name="providerNum"
                        type="text"
                        value={this.state.providerNum}
                        onChange={this.handleInputChange}
                    />
                </label>

                <label>
                    Zip Code:
          <input
                        name="zipcode"
                        type="text"
                        value={this.state.zipcode}
                        onChange={this.handleInputChange}
                    />
                </label>
                <button onClick={this.handleSearchClick}>
                    Search
                </button>
                <h1 id="tabelLabel" >Vaccine Statistics</h1>
                <p>COVID Vaccine Statistics</p>
                {contents}
            </div>
        );
    }

    async populateVaccineData(sort) {

        //const baseURL = process.env.REACT_APP_API_ENDPOINT;
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/vaccines/getvaccinestats?providerId=${encodeURIComponent(this.state.providerId)}&zipcode=${encodeURIComponent(this.state.zipCode)}&sort=${encodeURIComponent(this.state.sort)}`, 
        {
              method: 'GET',
              mode: 'cors',
          });

        const data = await response.json();
        if (response.ok) {
            this.setState({ vaccines: data, loading: false });
        }
        else { this.setState({ vaccines: data, loading: true });}
    }
}
