import React, { Component } from 'react';
import {Navbar, Container, Row, Nav, Form, FormControl, Button, Col, InputGroup} from 'react-bootstrap';

const { REACT_APP_API_URL } = process.env;

export default class VaccineUpdate extends Component {
    static displayName = VaccineUpdate.name;

    constructor(props) {
        super(props);
        this.state = {
            providerId: '',
            zipCode: '',
            providerName: '',
            providerCity: '',
            providerCounty: '',
            vaccineWeek: 'week2',
            vaccinePatients: 0,
            vaccineStaff: 0,
            vaccineOthers: 0,
            vaccines: [],
            responseMessage: ''

        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.providerUpdateElement = React.createRef();
        this.updateProvider = this.updateProvider.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        var value = target.value
        value = target.type === 'checkbox' ? target.checked : value;
        value = target.type === 'number' ? parseInt(value) : value;
        const name = target.name;
        if (target.type === 'select-one')
        {
          if (this.state.vaccines === undefined || this.state.vaccines.length == 0) {
            var currentVaccineData = new Array({patients: 0, staff: 0, other: 0});
          }
          else{
           var currentVaccineData = this.state.vaccines.filter(p => p.week == value);
           if (currentVaccineData == undefined || currentVaccineData == 0)
          {
            currentVaccineData = new Array({patients: 0, staff: 0, other: 0});
          }
         }
          
        
          this.setState({
            [name]: value,
            vaccinePatients: currentVaccineData[0].patients,
            vaccineStaff: currentVaccineData[0].staff,
            vaccineOthers: currentVaccineData[0].other
          });
        }
        else
        {
          this.setState({
            [name]: value
          });
        }

        
    }

    updateProvider(e) {
        let testthis = this;
        fetch('api/providers', {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
            },

            // We convert the React state to JSON and send it as the POST body
            body: JSON.stringify(this.state)
        }).then(function (response) {
            console.log(response)
            return response.json();
        }).then(function (data) {
            console.log(data)
            testthis.setState({
                lastUpdated: data.lastUpdated,
                updateStatus: "Record Updated Successfully"
            });
            
        });   

    }
    handleSubmit = (event) => {
        let testthis = this;
        //alert('A form was submitted: ' + this.state);
        event.preventDefault();
        this.populateProviderData();
        //fetch(`http://192.168.0.11:64672/api/vaccines/getprovidervaccines?providerId=${encodeURIComponent(this.state.providerId)}&zipcode=${encodeURIComponent(this.state.zipCode)}&showall=${encodeURIComponent(this.state.vaccineWeek)}`, {
        ////fetch('api/providers', {
        //    method: 'GET',
        //    mode: 'no-cors',
        //    //headers: {
        //   //     "Content-Type": "application/json",
        //   // },
//
  //          // We convert the React state to JSON and send it as the POST body
    //        //body: JSON.stringify(this.state)
    //    }).then(function (response) {
    //        console.log(response)
    //        if (response.ok) {
    //          const data = response.json();
    //          testthis.setState({providerName: data.providerName});
    //        }
    //        //else { testthis.setState({ providers: data, loading: true });}      
    //    })      
    }
    handleSubmit2 = (event) => {
      let testthis = this;
      //alert('A form was submitted: ' + this.state);
      event.preventDefault();
      this.updateVaccineData();    
  }

 
    async updateVaccineData() {

      //const baseURL = ${process.env.REACT_APP_API_URL};
      //const { REACT_APP_API_URL } = process.env;

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/vaccines/updatevaccinedata?providerId=${encodeURIComponent(this.state.providerId)}&zipcode=${encodeURIComponent(this.state.zipCode)}&week=${encodeURIComponent(this.state.vaccineWeek)}&patients=${encodeURIComponent(this.state.vaccinePatients)}&staff=${encodeURIComponent(this.state.vaccineStaff)}&other=${encodeURIComponent(this.state.vaccineOthers)}`, 
      {
        
            method: 'GET',
            mode: 'cors',
        })   
      const data = await response.json();
      if (response.ok) {
        if (data.vaccines === undefined || data.vaccines == 0) {
           var currentVaccineData = new Array({patients: 0, staff: 0, other: 0});
         }
         else{
          var currentVaccineData = data.vaccines.filter(p => p.week == this.state.vaccineWeek);
        }
          this.setState({ 
              providerName: data.providerName, 
              providerCity: data.city, 
              providerCounty: data.county, 
              vaccines: data.vaccines,
              vaccinePatients: currentVaccineData[0].patients,
              vaccineStaff: currentVaccineData[0].staff,
              vaccineOthers: currentVaccineData[0].other,
              responseMessage: '' 
            
            });
      }
      else { this.setState({ responseMessage: 'Not Found' });}
  }
    async populateProviderData() {

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/vaccines/getprovidervaccines?providerId=${encodeURIComponent(this.state.providerId)}&zipcode=${encodeURIComponent(this.state.zipCode)}&showall=${encodeURIComponent(this.state.vaccineWeek)}`, {
        
            method: 'GET',
            mode: 'cors',
        })   
      const data = await response.json();
      if (response.ok) {
        if (data.vaccines === undefined || data.vaccines == 0) {
           var currentVaccineData = new Array({patients: 0, staff: 0, other: 0});
         }
         else{
          var currentVaccineData = data.vaccines.filter(p => p.week == this.state.vaccineWeek);
          if (currentVaccineData == undefined || currentVaccineData == 0)
          {
            currentVaccineData = new Array({patients: 0, staff: 0, other: 0});
          }
        }
          this.setState({ 
              providerName: data.providerName, 
              providerCity: data.city, 
              providerCounty: data.county, 
              vaccines: data.vaccines,
              vaccinePatients: currentVaccineData[0].patients,
              vaccineStaff: currentVaccineData[0].staff,
              vaccineOthers: currentVaccineData[0].other,
              responseMessage: '' 
            
            });
      }
      else { this.setState({ responseMessage: 'Not Found' });}
  }

    render() {
      var weekOptions = [
        {key: "week1", value: "Dec 28 - Jan 3"},
        {key: "week2", value: "Jan 4 - Jan 10"},
        {key: "week3", value: "Jan 11 - Jan 17"},
        {key: "week4", value: "Jan 18 - Jan 24"},
        {key: "week5", value: "Jan 25 - Jan 31"},
        {key: "week6", value: "Feb 1 - Feb 7"},
        {key: "week7", value: "Feb 8 - Feb 14"},
        {key: "week8", value: "Feb 15 - Feb 21"},
        {key: "week9", value: "Feb 22 - Feb 28"},
        {key: "week10", value: "Mar 1 - March 7"},
        {key: "week11", value: "Mar 8 - Mar 14"},
        {key: "week12", value: "Mar 15 - Mar 21"},
      ]
      return (
          <div>
            <h4>{process.env.REACT_APP_API_URL}</h4>
        <h4>Provider Vaccinations Update</h4>
        <p>Enter your provider number and zip code and click find</p>
        <Form onSubmit={this.handleSubmit}>
  <Form.Row className="align-items-center">
  <Form.Group as={Col} sm={{ span: 2, offset: 2 }} controlId="formGridCity">
      <Form.Label>
        Provider Number
      </Form.Label>
      <Form.Control
        name="providerId" 
        type="text" 
        className="mb-2"
        id="inlineFormInput"
        placeholder="Provider Number" 
        value={this.state.providerId} 
        onChange={this.handleInputChange} 
      />
    </Form.Group>
    <Form.Group as={Col} sm={2} controlId="formGridCity">
      <Form.Label>
        Zip Code
      </Form.Label>
      <Form.Control
        name="zipCode" 
        type="text" 
        className="mb-2"
        id="inlineFormInput"
        placeholder="Provider Zip Code"
        value={this.state.zipCode}
        onChange={this.handleInputChange} 
      />
    </Form.Group>
    <Col xs="auto">
      <Form.Check
        type="checkbox"
        id="autoSizingCheck"
        className="mb-2"
        label="Remember me"
      />
    </Col>
    <Col xs="auto">
      <Button type="submit" className="mb-2">
        Find
      </Button>
    </Col>
  </Form.Row>
</Form>
<br></br>
<Container>
  <Row>
    <Col sm={2}></Col>
    <Col sm={2}><h5>Provider</h5></Col>
    <Col sm={2}><h5>City</h5></Col>
    <Col sm={2}><h5>County</h5></Col>
  </Row>
  <Row>
    <Col sm={2}></Col>
    <Col sm={2}>{this.state.providerName}</Col>
    <Col sm={2}>{this.state.providerCity}</Col>
    <Col sm={2}>{this.state.providerCounty}</Col>
  </Row>

</Container>
<br></br>
<Form onSubmit={this.handleSubmit2}>
  <Form.Row>
    <Form.Group as={Col} sm={{ span: 2, offset: 1 }} controlId="formGridCity">
      <Form.Label>Week</Form.Label>
      <Form.Control 
        name="vaccineWeek"
        id="vaccineWeek"
        as="select" 
        value={this.state.vaccineWeek} 
        options={weekOptions}
        onChange={this.handleInputChange} 
        >
      {
        weekOptions.map((option, index) => {
          if (option.value === this.state.vaccineWeek)
          {
            return (<option key={index} selected value={option.key}>{option.value}</option>)
          }
          else
          {
            return (<option key={index} value={option.key}>{option.value}</option>)
          }
        })
      }
      </Form.Control>
    </Form.Group>
    <Form.Group as={Col} sm={2} controlId="vaccinePatients">
      <Form.Label>Patients Vaccinated</Form.Label>
      <Form.Control  
          type="number" 
          name="vaccinePatients" 
          className="mb-2"
          id="vaccinePatients"
          placeholder="0"
          value={this.state.vaccinePatients}
          onChange={this.handleInputChange} />
    </Form.Group>
    <Form.Group as={Col}  sm={2} controlId="vaccineStaff">
      <Form.Label>Staff Vaccinated</Form.Label>
      <Form.Control   
          type="number" 
          name="vaccineStaff" 
          className="mb-2"
          id="vaccineStaff"
          placeholder="0"
          value={this.state.vaccineStaff}
          onChange={this.handleInputChange} />
    </Form.Group>
    <Form.Group as={Col}  sm={2} controlId="vaccineOthers">
      <Form.Label>Others Vaccinated</Form.Label>
      <Form.Control  
          type="number" 
          name="vaccineOthers" 
          className="mb-2"
          id="vaccineOthers"
          placeholder="0"
          value={this.state.vaccineOthers}
          onChange={this.handleInputChange} />
    </Form.Group>
    <Form.Group as={Col}  sm={2} controlId="vaccineTotal">
      <Form.Label>Total Vaccinated</Form.Label>
      <Form.Control   
          type="number" 
          name="vaccineTotal" 
          className="mb-2"
          id="vaccinePatients"
          placeholder="0"
          value={this.state.vaccinePatients + this.state.vaccineStaff + this.state.vaccineOthers}
          onChange={this.handleInputChange} />
    </Form.Group>
</Form.Row>
 

  <Button variant="primary" type="submit">
    Submit
  </Button>
</Form></div>
      );
    }
  }