import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Navbar, Container, Row, Nav, Form, FormControl, Button, Col, InputGroup} from 'react-bootstrap';


export class NavBar extends Component {
    render() {
      return (
        <Navbar bg="light" variant="light">
            <Container>
    <Navbar.Brand href="/">Vaccine Tracker</Navbar.Brand>
    <Nav className="mr-auto">
      
    </Nav>
    <Nav>
    <Nav.Link tag={Link} href="VaccineUpdate" className="text-dark" to="/vaccineupdate">Update Vaccine Data</Nav.Link>
      <Nav.Link tag={Link} href="VaccineStats"  className="text-dark" to="/vaccinestats">Vaccine Stats</Nav.Link>
      </Nav>
      </Container>
  </Navbar>
      );
    }
  }