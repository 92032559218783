
import { Component } from 'react';
import logo from './logo.svg';
import { Route, Switch} from 'react-router-dom';
import VaccineUpdate from './VaccineUpdate';
import { VaccineStats } from './VaccineStats';
import Footer from './Footer';
import { Home } from './Home';
import { NavBar } from './NavBar';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Navbar, Container, Row, Nav, Form, FormControl, Button, Col, InputGroup} from 'react-bootstrap';


export default class App extends Component {
  render() {
return(
    <div className="App">
      <NavBar/>
      <Container>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/vaccineupdate' component={VaccineUpdate} />
        <Route path='/vaccinestats' component={VaccineStats} />
      </Switch>
      </Container>
      <Footer />
    </div>
  );
}
}